// components/Portfolio.js
import React, { useState } from 'react';
import penImage from '../img/simbolo_pen.png';
import usdImage from '../img/simbolo_usd.png';
import eurImage from '../img/simbolo_eur.png';
import auImage from '../img/simbolo_au.png';
import agImage from '../img/simbolo_ag.png';
import cryptoImage from '../img/simbolo_crypto.png';

const portfolioItems = [
  { id: 'pen', name: 'PEN', description: 'Sol Peruano', image: penImage },
  { id: 'usd', name: 'USD', description: 'Dólar Estadounidense', image: usdImage },
  { id: 'eur', name: 'EURO', description: 'Unión Europea', image: eurImage },
  { id: 'au', name: 'AU', description: 'Oro', image: auImage },
  { id: 'ag', name: 'AG', description: 'Plata', image: agImage },
  { id: 'crypto', name: 'CRYPTO', description: 'Criptomonedas', image: cryptoImage },
];

function Portfolio() {
  const [filter, setFilter] = useState('todos');

  const filteredItems = filter === 'todos' 
    ? portfolioItems 
    : portfolioItems.filter(item => item.id === filter);

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-2">PORTFOLIO</h2>
        <p className="text-center text-gray-600 mb-8">Intercambio de divisas</p>
        <div className="flex justify-center mb-8">
          <div className="inline-flex rounded-md shadow-sm" role="group">
            {['todos', 'pen', 'usd', 'eur', 'au', 'ag', 'crypto'].map((f) => (
              <button
                key={f}
                onClick={() => setFilter(f)}
                className={`px-4 py-2 text-sm font-medium ${
                  filter === f
                    ? 'bg-teal-500 text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-50'
                } border border-gray-200 ${
                  f === 'todos' ? 'rounded-l-lg' : f === 'crypto' ? 'rounded-r-lg' : ''
                }`}
              >
                {f.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredItems.map((item) => (
            <div key={item.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <img src={item.image} alt={item.name} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{item.name}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Portfolio;