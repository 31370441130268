import React from 'react';
import phoneImage from '../img/Iphone.png';

function Destacamos() {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <img src={phoneImage} alt="Phone" className="w-full h-auto" />
          </div>
          <div className="w-full md:w-1/2 px-4">
            <h2 className="text-4xl font-bold mb-4">DESTACAMOS</h2>
            <p className="text-gray-600 mb-8">
              Consideramos que la confianza de nuestros clientes lo es todo. Por eso tenemos una comunicación constante con cada uno de ellos y así poder ser su primera opción.
            </p>
            <div className="space-y-6">
              <FeatureItem
                icon="👁️"
                title="TRANSPARENCIA"
                description="A través de las consultas personalizadas, transmitimos la información del mercado para que el cliente pueda decidir cómo y cuándo realizar sus cambios de divisas."
              />
              <FeatureItem
                icon="💰"
                title="COMISIONES BAJAS"
                description="Un precio de conversión lo más ajustado posible al mercado interbancario."
              />
              <FeatureItem
                icon="⚡"
                title="RAPIDEZ"
                description="Rapidez y seguridad. Todo el equipo trabaja para poder ofrecer un servicio eficaz."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function FeatureItem({ icon, title, description }) {
  return (
    <div className="flex">
      <div className="flex-shrink-0 mr-4">
        <span className="text-3xl">{icon}</span>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
}

export default Destacamos;