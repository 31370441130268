import React, { useState } from 'react';
import { Link } from 'react-router-dom';  // Remove 'useNavigate' from here if you're not using it elsewhere
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import logo from '../img/small-logo.png';

// Create a custom axios instance with the base URL
const api = axios.create({
  baseURL: 'https://51.210.113.236:3000/api',
  withCredentials: true,
});

export default function Header() {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [registrationStep, setRegistrationStep] = useState(1);
  const registrationSteps = [
    { title: "Personal Information", fields: [
      { name: "firstName", label: "First Name", type: "text" },
      { name: "lastName", label: "Last Name", type: "text" },
    ]},
    { title: "Account Details", fields: [
      { name: "email", label: "Email", type: "email" },
      { name: "password", label: "Password", type: "password" },
      { name: "confirmPassword", label: "Confirm Password", type: "password" },
    ]},
    { title: "Terms and Conditions", fields: [] }
  ];

  function closeLoginModal() {
    setIsLoginOpen(false);
  }

  function openLoginModal() {
    setIsLoginOpen(true);
  }

  function closeRegistrationModal() {
    setIsRegistrationOpen(false);
  }

  function openRegistrationModal() {
    setIsRegistrationOpen(true);
  }

  const onLogin = async (data) => {
    try {
      const response = await api.post('/auth/login', data, { withCredentials: true });
      console.log(response.data.message);
      closeLoginModal();
      // Redirect or update UI as needed
    } catch (error) {
      console.error('Login error:', error.response?.data?.message || 'An error occurred');
    }
  };

  const onRegister = async (data) => {
    try {
      const response = await api.post('/auth/register', data);
      console.log(response.data.message);
      closeRegistrationModal();
      // Show message to check email for verification
    } catch (error) {
      console.error('Registration error:', error.response?.data?.message || 'An error occurred');
    }
  };

  const onForgotPassword = async (data) => {
    try {
      const response = await api.post('/auth/forgot-password', data);
      console.log(response.data.message);
      setIsForgotPasswordOpen(false);
      // Show message to check email for password reset instructions
    } catch (error) {
      console.error('Forgot password error:', error.response?.data?.message || 'An error occurred');
    }
  };

  const handleNextStep = () => {
    setRegistrationStep(prev => Math.min(prev + 1, registrationSteps.length));
  };

  const handlePrevStep = () => {
    setRegistrationStep(prev => Math.max(prev - 1, 1));
  };

  return (
    <nav className="bg-black py-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="space-x-4">
          <Link to="/home" className="text-white text-sm font-medium hover:text-teal-500 transition-colors">
            SERVICIOS
          </Link>
        </div>
        <div className="overflow-hidden">
          <img src={logo} alt="BufaloCash Logo" className="w-14 h-14 object-contain rounded-none" />
        </div>
        <div className="space-x-4">
          <button
            onClick={openLoginModal}
            className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded transition-colors"
          >
            Login
          </button>
          <button
            onClick={openRegistrationModal}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors"
          >
            Register
          </button>
        </div>
      </div>

      {/* Login Modal */}
      <Transition appear show={isLoginOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeLoginModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Login
                  </Dialog.Title>
                  <div className="mt-2">
                    <form onSubmit={handleSubmit(onLogin)} className="space-y-4">
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                        />
                      </div>
                      <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                          Password
                        </label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-teal-100 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                        >
                          Log in
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            closeLoginModal();
                            setIsForgotPasswordOpen(true);
                          }}
                          className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                        >
                          Forgot Password?
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Registration Modal */}
      <Transition appear show={isRegistrationOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeRegistrationModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 mb-4"
                  >
                    Registration - {registrationSteps[registrationStep - 1].title}
                  </Dialog.Title>
                  
                  {/* Progress Bar */}
                  <div className="flex mb-4">
                    {registrationSteps.map((step, index) => (
                      <div 
                        key={index} 
                        className={`flex-1 h-2 ${index < registrationStep ? 'bg-teal-500' : 'bg-gray-200'}`}
                      ></div>
                    ))}
                  </div>

                  {/* Form Fields */}
                  <form onSubmit={handleSubmit(onRegister)} className="space-y-4">
                    {registrationSteps[registrationStep - 1].fields.map((field, index) => (
                      <div key={index}>
                        <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                          {field.label}
                        </label>
                        <input
                          type={field.type}
                          id={field.name}
                          {...register(field.name, { required: true })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                        />
                        {errors[field.name] && <span className="text-red-500 text-sm">This field is required</span>}
                      </div>
                    ))}

                    {registrationStep === registrationSteps.length && (
                      <div>
                        <label>
                          <input type="checkbox" {...register('isConsentGiven', { required: true })} />
                          I agree to the terms and conditions
                        </label>
                        {errors.isConsentGiven && <span className="text-red-500">This field is required</span>}
                      </div>
                    )}

                    <div className="flex justify-between">
                      {registrationStep > 1 && (
                        <button
                          type="button"
                          onClick={handlePrevStep}
                          className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                        >
                          Previous
                        </button>
                      )}
                      {registrationStep < registrationSteps.length ? (
                        <button
                          type="button"
                          onClick={handleNextStep}
                          className="inline-flex justify-center rounded-md border border-transparent bg-teal-100 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-teal-100 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                        >
                          Register
                        </button>
                      )}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Forgot Password Modal */}
      <Transition appear show={isForgotPasswordOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsForgotPasswordOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Forgot Password
                  </Dialog.Title>
                  <div className="mt-2">
                    <form onSubmit={handleSubmit(onForgotPassword)} className="space-y-4">
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          {...register('email', { required: true })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                        />
                        {errors.email && <span className="text-red-500 text-sm">This field is required</span>}
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-teal-100 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </nav>
  );
}