// components/Footer.js
import React from 'react';
import logo from '../img/footer-logo.png';

function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          <img src={logo} alt="BufaloCash Logo" className="h-12 w-auto mb-4" />
          <p className="text-center">
            Copyright © {new Date().getFullYear()} | Todos los derechos reservados | BufaloCash
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;