// components/Contact.js
import React from 'react';

function Contact() {
  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="bg-teal-500 text-white py-8 mb-8 text-center">
          <h2 className="text-4xl font-bold">HACEMOS TRANSFERENCIAS</h2>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <h3 className="text-2xl font-semibold mb-4">Información de Contacto</h3>
            <div className="space-y-4">
              <p><strong>UBICACIÓN:</strong> Lima - Perú</p>
              <p><strong>TELEGRAM:</strong> <a href="https://t.me/BufaloSoldier" className="text-teal-500 hover:underline">@BufaloSoldier</a></p>
              <p><strong>HORARIO:</strong><br />
                Lunes - Viernes: 9:00 - 18:00<br />
                Sábado: 9:00 - 13:00
              </p>
            </div>
            <div className="mt-8 flex space-x-4">
              <a href="https://facebook.com/yourpage" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-teal-500"><i className="fab fa-facebook text-2xl"></i></a>
              <a href="https://twitter.com/yourhandle" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-teal-500"><i className="fab fa-twitter text-2xl"></i></a>
              <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-teal-500"><i className="fab fa-instagram text-2xl"></i></a>
              <a href="https://linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-teal-500"><i className="fab fa-linkedin text-2xl"></i></a>
              <a href="https://github.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-teal-500"><i className="fab fa-github text-2xl"></i></a>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <form>
              <div className="mb-4">
                <input type="text" placeholder="Tu Nombre *" className="w-full px-4 py-2 border border-gray-300 rounded" required />
              </div>
              <div className="mb-4">
                <input type="tel" placeholder="Tu Teléfono *" className="w-full px-4 py-2 border border-gray-300 rounded" required />
              </div>
              <div className="mb-4">
                <textarea placeholder="Tu mensaje *" rows="5" className="w-full px-4 py-2 border border-gray-300 rounded" required></textarea>
              </div>
              <button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded">
                ENVIAR MENSAJE
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
