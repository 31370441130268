import backgroundImage from '../img/pw_maze_black_2X.png';
import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import logo from '../img/logo.png';
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://51.210.113.236:3000/api',
  withCredentials: true,
});

export default function Home() {
  const [isVisible, setIsVisible] = useState(false)
  const navigate = useNavigate()
  const [amount, setAmount] = useState('');
  const [convertedAmount, setConvertedAmount] = useState('');
  const [currency, setCurrency] = useState('PEN');
  const [conversionRate, setConversionRate] = useState('');
  const [fee, setFee] = useState('');
  const [total, setTotal] = useState('');

  const updateValues = useCallback(async () => {
    if (!amount) return;

    try {
      const response = await api.post('/calculator/calculate', {
        amount: parseFloat(amount),
        currencyType: currency,
      });

      const data = response.data;
      setConvertedAmount(data.convertedAmount.toFixed(2));
      setConversionRate(data.conversionRate.toFixed(2));
      setFee(data.fee);
      setTotal(data.total);
    } catch (error) {
      console.error('Error calculating results:', error);
    }
  }, [amount, currency]);

  useEffect(() => {
    setIsVisible(true)
    updateValues();
  }, [updateValues])

  const handleStart = () => {
    navigate('/home')
  }

  const handleConvertedAmountChange = async (e) => {
    const value = e.target.value;
    setConvertedAmount(value);

    try {
      const response = await api.get(`/calculator/info/${currency}`);
      const currencyValue = response.data.message;
      const result = parseFloat(value) / parseFloat(currencyValue);
      setAmount(result.toFixed(2));
    } catch (error) {
      console.error('Error updating values:', error);
    }
  };

  const executeOperation = async () => {
    try {
      const response = await api.post('/remittance/execute-remittance', {
        amount: parseFloat(amount),
        convertedAmountValue: parseFloat(convertedAmount),
        currencyType: currency,
      });

      if (response.status === 200) {
        alert('Transfer Successful! Thank you for using our service.');
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error executing operation:', error);
      alert('An error occurred while processing your request.');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: isVisible ? 1 : 0 }}
      transition={{ duration: 1 }}
      className="min-h-screen flex items-center justify-center relative overflow-hidden"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'repeat',
        backgroundPosition: 'left'
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-10 z-0"></div>

      <div className="z-10 flex items-start justify-center w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mr-8">
          <div className="mb-8 flex justify-center items-center">
            <img src={logo} alt="BufaloCash Logo" className="w-24 h-24 object-contain rounded-none" />
          </div>
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-4">Bienvenido a BufaloCash</h1>
          <p className="text-xl text-gray-300 mb-8">LA MEJOR ELECCIÓN PARA TUS DIVISAS</p>
          <button
            onClick={handleStart}
            className="bg-teal-500 text-white font-bold py-2 px-6 rounded-md hover:bg-teal-600 transition-colors duration-300"
          >
            EMPEZAR
          </button>
        </div>

        <div className="bg-white bg-opacity-90 p-8 rounded-lg shadow-lg max-w-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Currency Calculator</h2>
          <div className="space-y-4">
            <div>
              <label htmlFor="amount" className="block text-sm font-medium text-gray-700">You send (EUR):</label>
              <input
                type="number"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="100.00"
                step="0.01"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Exchange rate:</label>
              <div className="mt-1 p-2 bg-gray-100 rounded-md">
                {conversionRate ? `1 EUR = ${conversionRate} ${currency}` : '-'}
              </div>
            </div>

            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md">
              <div className="mb-4">
                <label htmlFor="convertedAmount" className="block text-sm font-medium text-gray-700">Recipient gets:</label>
                <input
                  type="number"
                  id="convertedAmount"
                  value={convertedAmount}
                  onChange={handleConvertedAmountChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  step="0.01"
                />
              </div>

              <div>
                <label htmlFor="currency" className="block text-sm font-medium text-gray-700">Receiving currency:</label>
                <select
                  id="currency"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="PEN">Sol (PEN)</option>
                  <option value="USD">Dollar (USD)</option>
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Fee:</label>
              <div className="mt-1 p-2 bg-gray-100 rounded-md text-right">
                {fee || '-'}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Total:</label>
              <div className="mt-1 p-2 bg-gray-100 rounded-md text-right font-bold">
                {total ? `${total} EUR` : '-'}
              </div>
            </div>

            <button
              onClick={executeOperation}
              className="w-full bg-teal-500 text-white font-bold py-2 px-6 rounded-md hover:bg-teal-600 transition-colors duration-300"
            >
              Send Money
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
