// // components/Services.js
// import React from 'react';
// import { Link, useParams } from 'react-router-dom';
// import macbookImage from '../img/MacBook.jpeg';

// function Services() {
//   const { filter = 'all' } = useParams();

//   const serviceItems = [
//     { id: 'exchange', name: 'Casa de Cambio', description: 'Servicio de compra y venta de moneda extranjera. Podrás intercambiar tus divisas de forma online o delivery.' },
//     { id: 'remittance', name: 'Envíos de Dinero', description: 'Envía dinero al extranjero. Podrás realizar tus remesas de forma rápida y segura.' },
//     { id: 'minerals', name: 'Comercio de Minerales', description: 'Compramos oro y plata. Trae tus minerales y los tasaremos al mejor precio del mercado.' },
//     { id: 'consulting', name: 'Consultoría', description: 'Trato personalizado con cada cliente.' },
//   ];

//   const filteredServices = filter === 'all'
//     ? serviceItems
//     : serviceItems.filter(item => item.id === filter);

//   return (
//     <section id="service" className="section main-section">
//       <div className="container">
//         <h2>Servicios</h2>
//         <h6>Ofrecemos un servicio excepcional para cambiar tus divisas</h6>
//         <div className="serviceFilter">
//           <ul>
//             <li><Link to="/services/all" className={filter === 'all' ? 'current' : ''}>TODOS</Link></li>
//             <li><Link to="/services/exchange" className={filter === 'exchange' ? 'current' : ''}>Casa de Cambio</Link></li>
//             <li><Link to="/services/remittance" className={filter === 'remittance' ? 'current' : ''}>Envíos de Dinero</Link></li>
//             <li><Link to="/services/minerals" className={filter === 'minerals' ? 'current' : ''}>Comercio de Minerales</Link></li>
//             <li><Link to="/services/consulting" className={filter === 'consulting' ? 'current' : ''}>Consultoría</Link></li>
//           </ul>
//         </div>
//         <div className="row">
//           <div className="col-lg-4 col-sm-6 wow fadeInLeft delay-05s">
//             {filteredServices.map(service => (
//               <div key={service.id} className="service-list">
//                 <div className="service-list-col1"></div>
//                 <div className="service-list-col2">
//                   <h3>{service.name}</h3>
//                   <p>{service.description}</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//           <figure className="col-lg-8 col-sm-6 text-right wow fadeInUp delay-02s">
//             <img src={macbookImage} alt="MacBook" />
//           </figure>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Services;

// components/Services.js
import React from 'react';
import laptopImage from '../img/MacBook.jpeg';

function Services() {
  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-2">SERVICIOS</h2>
        <p className="text-center text-gray-600 mb-12">Ofrecemos un servicio excepcional para cambiar tus divisas</p>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <div className="space-y-8">
              <ServiceItem
                title="CASA DE CAMBIO"
                description="Servicio de compra y venta de moneda extranjera. Podrás intercambiar tus divisas de forma online o delivery."
                icon="💱"
              />
              <ServiceItem
                title="ENVÍOS DE DINERO"
                description="Envía dinero al extranjero. Podrás realizar tus remesas de forma rápida y segura."
                icon="✈️"
              />
              <ServiceItem
                title="COMERCIO DE MINERALES"
                description="Compramos oro y plata. Trae tus minerales y los tasaremos al mejor precio del mercado."
                icon="💎"
              />
              <ServiceItem
                title="CONSULTORÍA"
                description="Trato personalizado con cada cliente."
                icon="👨‍💼"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <img src={laptopImage} alt="Laptop" className="w-full h-auto" />
          </div>
        </div>
      </div>
    </section>
  );
}

function ServiceItem({ title, description, icon }) {
  return (
    <div className="flex">
      <div className="flex-shrink-0 mr-4">
        <span className="text-3xl">{icon}</span>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
}

export default Services;